<template>
  <div class="wrap">
    <!-- 搜索框 -->
    <div class="search-wrap">
      <!-- <van-search v-model="value" placeholder="请输入搜索关键词" /> -->
      <van-search
        v-model="flowTitle"
        show-action
        placeholder="请输入代办关键字"
        @search="onSearch"
      >
        <template #action>
          <div @click="onSearch" style="height:0.2rem">搜索</div>
        </template>
      </van-search>
    </div>
    <!-- 办件 -->
    <!-- <van-pull-refresh v-model="isLoading" @refresh="onSearch"> -->
    <div style="height: 100vh;overflow: hidden;">
      <vo-pages :config="voConfig" :data="todoDataList" @pullingUp="pullingUp" @pullingDown="pullingDown" :loadedAll="loadedAll">
        <div v-if="!todoLoading && !toReadLoading">
          <div class="todo-wrap">
            <div class="todo_tip">
              <div class="todo_tip_box">
                <div class="todo_tip_left">
                  <img src="@/assets/todo_icon_1.png" alt="">
                  <h2 class="todo_title">办件</h2>
                </div>
                <div class="todo_tip_right">
                  待办件列表（<span>{{todoTotal}}</span>件）
                </div>
              </div>
            </div>
            <div class="todo_main">
              <div class="todo_item" v-for="(item,index) in todoDataList" :key="index" @click="handleToMain(item)">
                <h2 class="todo_main_title">{{item.flowTitle}}</h2>
                <div class="todo_name_time">
                  <div style="display:inline-block;width:60px">
                    <div class="todo_name">{{item.processCreatorName}}</div>
                  </div>
                  <div class="todo_time">
                    <img src="@/assets/todo_icon_3.png" alt=""> {{item.processStartTime}}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- 阅件 -->
          <!-- <div class="todo-wrap" style="margin-bottom:10px">
            <div class="todo_tip">
              <div class="todo_tip_box">
                <div class="todo_tip_left">
                  <img src="@/assets/todo_icon_1.png" alt="">
                  <h2 class="todo_title">阅件</h2>
                </div>
                <div class="todo_tip_right">
                  待阅件列表（<span>{{toReadTotal}}</span>件）
                </div>
              </div>
            </div>
            <div class="todo_main">
              <div class="todo_item" v-for="(item,index) in toReadDataList" :key="index">
                <h2 class="todo_main_title">{{item.flowTitle}}</h2>
                <div class="todo_name_time">
                  <div style="display:inline-block;width:60px">
                    <div class="todo_name">{{item.flowPassReaderName}}</div>
                  </div>
                  <div class="todo_time">
                    <img src="@/assets/todo_icon_3.png" alt=""> {{item.processStartTime}}
                  </div>
                </div>
              </div>
            </div>
          </div> -->
        </div>
      </vo-pages>
    </div>
    <!-- <div v-else class="flex-center">
      <van-loading size="24px">加载中...</van-loading>
    </div> -->
    <!-- </van-pull-refresh> -->
    <!-- <div class="reading-wrap"></div> -->
  </div>
</template>
<script>
import { Search } from "vant";
import { myFlowList } from  '@/api/index'
export default {
  components: {
    VanSearch: Search,
  },
  data() {
    return {
      voConfig:{
        loadedAllMsg:'没有更多待办数据了',
        pullUpMsg:'',
        loading:'加载中',
        refresh:'刷新中'
      },
      flowTitle: "",
      todoLoading:false,
      toReadLoading:false,
      todoDataList:[],
      toReadDataList:[],
      queryParamsTodo:{
        taskType:'processToDo',
        pageSize:10,
        pageNumber:1
      },
      toReadTotal:0,
      todoTotal:0,
      queryParamsToRead:{
        taskType:'processUnread',
        pageSize:10,
        pageNumber:1
      },
      isLoading:false,
      loadedAll:false,
    };
  },
  created() {
    this.getTodoList(false);
    // this.getToReadList();
    this.$store.dispatch('delAllCachedViews');
  },
  methods: {
    pullingDown () {
      this.beforePullDown = false
      this.queryParamsTodo.pageNumber = 1
      this.getTodoList(false)
    },
    pullingUp () {
      this.queryParamsTodo.pageNumber += 1
      this.getTodoList(true)
    },
    onSearch(){
      this.getTodoList(false);
      // this.getToReadList();
      this.isLoading = false;
    },
    handleToMain(data){
      this.$router.push('/main?instanceId='+data.processInstId+'&taskId='+data.taskId+'&modelKey='+data.modelKey+'&tenantId='+data.tenantId+'&flowTitle='+data.flowTitle+'&type=todo')
    },
    getTodoList(loadMore = true){
      this.queryParamsTodo.flowTitle = this.flowTitle
      if(!loadMore){
        this.todoLoading = true;
      }
      myFlowList(this.queryParamsTodo).then(res => {
        if(res.code == 200) {
          const newList = res.data.rows;
          this.todoTotal = res.data.total
          if (loadMore) {
            this.todoDataList = this.todoDataList.concat(newList)
          } else {
            this.todoDataList = newList
          }
          if (!this.beforePullDown) {
            this.beforePullDown = true
          }
          this.loadedAll = this.todoTotal <= this.todoDataList.length
        }
        this.todoLoading = false;
      })
    },
    // getToReadList(){
    //   this.queryParamsToRead.flowTitle = this.flowTitle
    //   this.toReadLoading = true;
    //   myFlowList(this.queryParamsToRead).then(res => {
    //     if(res.code == 200) {
    //       this.toReadDataList = res.data.rows;
    //       this.toReadTotal = res.data.total
    //     }
    //     this.toReadLoading = false;
    //   })
    // }
  }
};
</script>
<style lang="scss" scoped>
.van-search{
    padding: 0;
}
.van-search__content{
    background: #F5F5F5;
    border-radius: 0.04rem;
}
.van-search__action{
    width: 0.67rem;
    height: 0.34rem;
    background: #3A75C5;
    border-radius: 0.04rem;
    color: #fff;
    text-align: center;
    margin-left: 0.06rem;
    padding: 0;
    // font-size: 0.13rem;
}
.wrap {
    background: #F5F5F5;
  .search-wrap {
    padding: 0.09rem 0.12rem;
    background: #fff;
  }
  .todo-wrap{
    background: #fff;
    margin-top: 0.09rem;
    .todo_tip{
      border-bottom: 0.01rem solid #EEEEEE;
      .todo_tip_box{
        padding: 0.07rem 0.12rem;
        display: flex;
        justify-content: space-between;
      }
      img{
        width: 0.19rem;
        height: 0.19rem;
        position: relative;
        top: 0.03rem;
      }
      .todo_title{
        display: inline-block;
        color: #3A75C5;
        font-size: 0.18rem;
        margin-left: 0.06rem;
      }
      .todo_tip_right{
        height: 0.25rem;
        line-height: 0.25rem;
        color: #888888;
      }
    }
    .todo_main{
      padding: 0 0.08rem;
      .todo_item{
        padding-top: 0.12rem;
        padding-bottom: 0.13rem;
        border-bottom: 0.01rem solid #EEEEEE;
        .todo_main_title{
          padding-bottom: 0.07rem;
          font-size: 0.15rem;
          color: #333333;
          word-break:break-all;
          line-height: 23px;
          // position: relative;
          // left: -6px;
        }
        .todo_name_time{
          // display: flex;
          // justify-content: space-between;
          margin-left: 0.06rem;
          .todo_name{
            display: inline-block;
            padding: 0.01rem 0.07rem;
            border-radius: 0.02rem;
            border: 0.01rem solid #3A75C5;
            color: #3A75C5;
            font-size: 0.09rem;
          }
          .todo_time{
            display: inline-block;
            margin-left: 0.2rem;
            color: #888888;
            img{
              width: 0.13rem;
              height: 0.13rem;
              position: relative;
              top: 0.02rem;
            }
          }
        }
      }
      .todo_item:last-child{
        border: 0;
      }
    }

  }
}
</style>
